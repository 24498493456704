import React from 'react';
import { Route, HashRouter as Router } from 'react-router-dom';
import { createHashHistory, HashHistory } from 'history';

import Dashboard from './components/pages/Dashboard/Dashboard';
import {default as DocumentList} from './components/pages/Document/List/List';
import {default as DocumentNew} from './components/pages/Document/New/New';
import {default as DocumentShow} from './components/pages/Document/Show/Show';
import {default as DocumentSign} from './components/pages/Document/Sign/Sign';

const defaultHistory = createHashHistory();

interface ApplicationRouterProps {
	history : HashHistory
}

const ApplicationRouter = ({ history = defaultHistory }: ApplicationRouterProps) => {
    return(
        <Router >
            <Route exact path="/assinadorMF"> 
				<Dashboard /> 
			</Route>
            <Route exact path="/assinadorMF/documents">
				<DocumentList />
			</Route>
			<Route exact path="/assinadorMF/documents/new">
				<DocumentNew />
			</Route>
			<Route exact path="/assinadorMF/documents/:id/show">
				<DocumentShow />
			</Route>
			<Route exact path="/assinadorMF/documents/:id/sign"> 
				<DocumentSign />
			</Route>
			<Route exact path="/assinadorMF/documents/:id/sign/:pwd"> 
				<DocumentSign />
			</Route>
        </Router>
    );
}

export default ApplicationRouter;