import { HashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/templates/App/App';

import './index.scss';


declare global {
    interface Window { 
        renderBirdman: any;
        unmountBirdman: any;
    }
}

window.renderBirdman = (containerId: string, history: HashHistory, store: any): Boolean => {
    ReactDOM.render(
        <App history={history} />,
        document.getElementById(containerId),
    );
    return true
};

window.unmountBirdman = (containerId: string):Boolean => {
    const domElement =  document.getElementById(containerId) as Element;
    ReactDOM.unmountComponentAtNode(domElement);
    return true
};

if (document.getElementById('birdman-container') == null) {
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root')
    );
}
