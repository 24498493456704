import React, { useEffect, useState } from 'react';
import { Button, DateTimePicker, Loading, Input, PageTitle, Pagination, Panel, PerPage, Row, Select, Table, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchAllDocuments } from '../../../../services/document.services';

import styles from './List.module.scss';
import { useHistory } from 'react-router';
import DocumentSignatures from '../../../../models/DocumentSignatures';
import StatusInformation from '../../../atoms/StatusInformation/StatusInformation';

interface ListDocumentProps {
	Component?: any
}

interface ListDocumentParams {
	pageNumber: number,
	quantityPerPage: number,
	status?: string
}

interface ListDocumentFilters {
	originalName: string | null,
	startDate: string | null,
	endDate: string | null,
	status: string,
	documentType: string
}

const List = ({ Component = 'div' }: ListDocumentProps) => {

	const [documents, setDocuments] = useState<DocumentSignatures[]>();
	const [documentsQuantity, setDocumentsQuantity] = useState(0);
	const [documentsLoading, setDocumentsLoading] = useState(true);
	const [params, setParams] = useState<ListDocumentParams>({ pageNumber: 1, quantityPerPage: 10 });
	const [filters, setFilters] = useState<ListDocumentFilters>({ originalName: null, startDate: null, endDate: null, status: '', documentType: '' });
	let history = useHistory();

	useEffect(() => {
		setDocumentsLoading(true);
		let paramsApi = { ...params };
		if (paramsApi.status === '') {
			delete paramsApi.status;
		}
		fetchAllDocuments(paramsApi).then(
			(response: any) => {
				if (response?.data) {
					setDocuments(response.data.data['documents']);
					setDocumentsQuantity(response.data.data['documentsQuantity']);
				}

				setDocumentsLoading(false);
			},
			error => {
				Promise.reject(error)
				setDocuments([]);
				setDocumentsLoading(false);
			});
	}, [params]);

	const TRANSLATE_DOCUMENT_TYPE_ICON: { [key: string]: any } = {
		'application/pdf': 'file-pdf',
		'application/msword': 'file-word',
		'image/jpeg': 'file-image',
		'image/png': 'file-image'

	}

	const statusOptions = [
		{ key: '', value: 'Todos', selected: true },
		{ key: 'waiting', value: 'Aguardando', selected: false },
		{ key: 'opened', value: 'Aberto', selected: false },
		{ key: 'signed', value: 'Assinado', selected: false },
		{ key: 'refused', value: 'Recusado', selected: false },
		{ key: 'canceled', value: 'Cancelado', selected: false },
		{ key: 'expired', value: 'Expirado', selected: false },
		{ key: 'copy', value: 'Cópia', selected: false },
	];

	const types: { [key: string]: string } = {
		'': 'Todos',
		'waiting': 'Aguardando',
		'opened': 'Aberto',
		'signed': 'Assinado',
		'refused': 'Recusado',
		'canceled': 'Cancelado',
		'expired': 'Expirado',
		'copy': 'Cópia',
	}

	const documentTypeOptions = [
		{ key: '', value: 'Todos', selected: true },
		{ key: 'uploaded', value: 'Documento carregado', selected: false },
		{ key: 'timeSheet', value: 'Espelho de Ponto', selected: false }
	];

	const documentTypes: { [key: string]: string } = {
		'': 'Todos',
		'uploaded': 'Documento carregado',
		'timeSheet': 'Espelho de Ponto'
	};

	const translateDocumentType = (documentType: string) => {
		return documentType === 'TIME_SHEET' ? 'Espelho de ponto' : 'Documento carregado';
	};

	const renderComponentDocumentType = (documentType: string) => (

		<Component className={styles['document-type']}>
			<FontAwesomeIcon icon={['fal', TRANSLATE_DOCUMENT_TYPE_ICON[documentType]]} size='2x' />
		</Component>

	)

	return (
		<Component className={styles['document-list']}>
			<Row>
				<Row.Item lg={12} md={12} sm={12} className=''>
					<PageTitle.Small title="Assinatura de documentos" />
				</Row.Item>
				<Row.Item lg={12} md={12} sm={12} className=''>
					<Panel title="Filtros" noTitleBorder>
						<Row>
							<Row.Item lg={2} md={2} sm={12} className={styles['filter-inputs']}>
								<Input
									medium
									value={filters?.originalName}
									name='documentName'
									title='Nome do documento'
									fullWidth
									className={styles['input-rt']}
									handleChange={(e) => {
										setFilters({ ...filters, originalName: e.target.value });
									}} />
							</Row.Item>
							<Row.Item lg={2} md={2} sm={12} className={styles['filter-inputs']}>
								<DateTimePicker
									title='Data inicial'
									name='startDate'
									type='date'
									mask
									medium
									fullWidth
									value={filters?.startDate}
									noSeconds
									forceOpenPicker={false}
									timeInverseColor
									handleChange={(e) => {
										setFilters({ ...filters, startDate: formatLocalDate(e, false, 'DD/MM/YYYY', 'YYYY-MM-DD') })
									}} />
							</Row.Item>
							<Row.Item lg={2} md={2} sm={12} className={styles['filter-inputs']}>
								<DateTimePicker
									title='Data final'
									name='endDate'
									type='date'
									mask
									medium
									fullWidth
									value={filters?.endDate}
									noSeconds
									forceOpenPicker={false}
									timeInverseColor
									handleChange={(e) => {
										setFilters({ ...filters, endDate: formatLocalDate(e, false, 'DD/MM/YYYY', 'YYYY-MM-DD') })
									}} />
							</Row.Item>
							<Row.Item lg={2} md={2} sm={12} className={styles['filter-inputs']}>
								<Select value={types[filters?.status]}
									name='documentStatus'
									medium
									title='Status'
									fullWidth
									options={statusOptions}
									className={styles['input-rt']}
									handleChange={(key) => {
										setFilters({ ...filters, status: key });
									}} />
							</Row.Item>
							<Row.Item lg={2} md={2} sm={12} className={styles['filter-inputs']}>
								<Select value={documentTypes[filters?.documentType]}
									name='documentTypes'
									medium
									title='Tipo de documento'
									fullWidth
									options={documentTypeOptions}
									className={styles['input-rt']}
									handleChange={(key) => {
										setFilters({ ...filters, documentType: key });
									}} />
							</Row.Item>
							<Row.Item lg={2} md={2} sm={12} className={styles['filter-actions']}>
								<Button title='Buscar'
									primary
									icon='search'
									action={() => {
										setParams({ ...params, ...filters })
									}} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
				<Row.Item lg={12} md={12} sm={12} className=''>
					<Panel title="Listagem de Documentos" defaul className={styles['panel-documents']}>
						<>
							{(documentsLoading) && <Loading text="Carregando os documentos" />}
							{(!documentsLoading) &&
								<>
									<Row>
										<Row.Item sm={12} md={12} lg={12} className=''>
											<Component className={styles['actions']}>
												<Button warn={false} success title='Cadastrar Documento' icon="plus" action={() => history.push('/assinadorMF/documents/new')} />
											</Component>
										</Row.Item>
									</Row>
									{documents?.length === 0 &&
										<Row>
											<Row.Item sm={12} md={12} lg={12} className=''>
												<TextInformation icon='search' text='Nenhum documento foi encontrado' iconType='fas' />
											</Row.Item>
										</Row>
									}
									{documents?.length !== 0 &&
										<Row>
											<Row.Item sm={12} md={12} lg={12} className=''>
												<Component className={styles['action-perpage']}>
													<Pagination.Compact currentPage={params.pageNumber}
														totalRecords={documentsQuantity}
														pageLimit={params.quantityPerPage}
														handlePageChanged={(pageNumber) => setParams({ ...params, pageNumber })} />
												</Component>
												<Component className={styles['action-perpage']}>
													<PerPage selected={params.quantityPerPage} handlePerPageChanged={(quantityPerPage) => setParams({ ...params, quantityPerPage })} />
												</Component>
											</Row.Item>
											<Row.Item sm={12} md={12} lg={12} className=''>
												{documents && <Table>
													<Table.Header>
														<Table.HeaderItem>
															<Table.HeaderItem.Item></Table.HeaderItem.Item>
															<Table.HeaderItem.Item>Documento</Table.HeaderItem.Item>
															<Table.HeaderItem.Item>Tipo</Table.HeaderItem.Item>
															<Table.HeaderItem.Item>Data do Documento</Table.HeaderItem.Item>
															<Table.HeaderItem.Item>Solicitante</Table.HeaderItem.Item>
															<Table.HeaderItem.Item>Assinante</Table.HeaderItem.Item>
															<Table.HeaderItem.Item>Status</Table.HeaderItem.Item>
															<Table.HeaderItem.Item>Opções</Table.HeaderItem.Item>
														</Table.HeaderItem>
													</Table.Header>
													<Table.Body>

														{documents?.map((doc, index) =>
															<Table.BodyItem key={index}>
																<Table.BodyItem.Item>{renderComponentDocumentType(doc.document?.contentType!)}</Table.BodyItem.Item>
																<Table.BodyItem.Item>{doc.document?.originalName}</Table.BodyItem.Item>
																<Table.BodyItem.Item>{translateDocumentType(doc.document?.documentType!)}</Table.BodyItem.Item>
																<Table.BodyItem.Item>{formatLocalDate(doc.document?.created, false, 'yyyy-MM-DDTHH:mm:ss.sss', 'DD/MM/yyyy HH:mm:ss')}</Table.BodyItem.Item>
																<Table.BodyItem.Item>{doc.document?.requesterName}</Table.BodyItem.Item>
																<Table.BodyItem.Item>{doc.signatures?.map(signature => { return <Component key={signature.id} className={styles['subscriber']}>{signature.subscriberName}</Component> })}</Table.BodyItem.Item>
																<Table.BodyItem.Item>
																	<StatusInformation statusName={doc.document?.status!} lastUpdate={doc.document?.modified!} />
																</Table.BodyItem.Item>
																<Table.BodyItem.Item>
																	<Button primary
																		title="Detalhar"
																		action={() => history.push(`/assinadorMF/documents/${doc.document?.id}/show`)} />
																</Table.BodyItem.Item>
															</Table.BodyItem>
														)}
													</Table.Body>
												</Table>
												}
											</Row.Item>
											<Row.Item sm={12} md={12} lg={12} className=''>
												<Component className={styles['pagination']}>
													<Pagination.Compact currentPage={params.pageNumber}
														totalRecords={documentsQuantity}
														pageLimit={params.quantityPerPage}
														handlePageChanged={(pageNumber) => setParams({ ...params, pageNumber })} />
												</Component>
											</Row.Item>
										</Row>
									}


								</>
							}
						</>
					</Panel>
				</Row.Item>
			</Row>
		</Component>
	)

}

export default List;