import React, { useEffect, useState } from 'react';

import { Alert, Button, Input, Loading, Modal, PreLoader, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Sign.module.scss';
import { useParams } from 'react-router';
import { fetchSignature, updateSignatureStatus } from '../../../../services/signature.services';
import Signature from '../../../../models/Signature';

interface SignDocumentProps {
	Component?: any
}
interface SignDocumentParams {
	id: string,
	pwd?: string
}

interface OperationFeedbackModel {
	status: string,
	message: string,
	showFeedback: boolean
}


const Sign = ({ Component = 'div' }: SignDocumentProps) => {
	const [documentoPasswordValid, setDocumentoPasswordValid] = useState<boolean>();
	const [documentoPasswordMessage, setDocumentoPasswordMessage] = useState<string>('');
	const [operationFeedback, setOperationFeedback] = useState<OperationFeedbackModel>({
		'status': '',
		'message': '',
		'showFeedback': false
	});
	const [files, setFiles] = useState<any[]>([]);
	const [signature, setSignature] = useState<Signature>();
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingDuration, setLoadingDuration] = useState<number>(5);
	const [showDocumentPreview, setShowDocumentPreview] = useState<boolean>(false);
	const [pwd, setPwd] = useState<string>('');
	const [params, setParams] = useState<SignDocumentParams>(useParams());


	useEffect(() => {
		const featDocumentDetails = () => {
			setLoading(true);
			setDocumentoPasswordValid(true);
			setDocumentoPasswordMessage('');
			fetchSignature(params.id, { 'password': params.pwd }).then(
				(response: any) => {
					if (response.data.status === 'SUCCESS') {
						setFiles(response.data.data.file);
						setSignature(response.data.data.signature);
						setDocumentoPasswordValid(true);
						setShowDocumentPreview(true);
					} else {
						setDocumentoPasswordMessage(response.data.message);
						setLoadingDuration(1);
						setLoading(false);
						setDocumentoPasswordValid(false);
						setShowDocumentPreview(false);
					}
	
				},
				error => {
					Promise.reject(error)
				}
			);
	
	
		}

		if (params.pwd) {
			featDocumentDetails();
		}
	}, [params])

	

	const changeSignatureStatus = (status: string) => {
		setOperationFeedback({ ...operationFeedback, status: 'PROCESSING', showFeedback: true })
		setDocumentoPasswordMessage('');
		updateSignatureStatus(params.id, { 'password': params.pwd, 'status': status }).then(
			(response: any) => {
				if (response.data.status === 'SUCCESS') {
					setOperationFeedback({ ...operationFeedback, message: `Documento ${status === 'signed' ? 'assinado' : 'rejeitado'} com sucesso`, status: response.data.status, showFeedback: true });
					setSignature(response.data.data.signature);
				} else {
					setOperationFeedback({ ...operationFeedback, message: `Problema ao ${status === 'signed' ? 'sssinar' : 'rejeitar'} o documento`, status: response.data.status, showFeedback: true });
				}

			},
			error => {
				Promise.reject(error)
			}
		);


	}

	const renderModalPassword = () => (
		<Modal backdrop xs title='Autenticação' className={styles['modal-sign-password']}>
			{documentoPasswordMessage !== '' && <Alert warn text={documentoPasswordMessage} />}
			<FontAwesomeIcon icon={['fal', 'key']} size='4x' className={styles['modal-icon']} />
			<p>Para continuar, insira a chave de autenticação informada no e-mail.</p>
			<Input type='password'
				fullWidth
				placeholder='Chave de Autenticação'
				handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const target = e.target;
					const value = target.value;
					setPwd( value)
				}}
			/>
			<Button
				title='Ver Documento'
				primary
				action={() => { setParams(({ ...params, pwd: pwd })) }}
			/>
		</Modal>
	)

	const renderModalFeedback = () => (
		<Modal
			backdrop xs
			title='Assinatura'
			className={styles['modal-sign-feedback']} >
			{operationFeedback.status !== 'PROCESSING' && (
				<>
					<TextInformation
						iconColor={operationFeedback.status === 'SUCCESS' ? '#3AA76D' : '#D4644D'}
						icon={operationFeedback.status === 'SUCCESS' ? 'check-circle' : 'times-circle'}
						iconType="fal"
						text={operationFeedback.message} />
					<Component className={styles['button-action']}>
						<Button
							md title="OK"
							danger={operationFeedback.status !== 'SUCCESS'}
							primary={operationFeedback.status === 'SUCCESS'}
							action={() => { setOperationFeedback({ ...operationFeedback, 'showFeedback': false }) }}></Button>
					</Component>
				</>
			)}
			{operationFeedback.status === 'PROCESSING' && (
				<Loading text="Sua solicitação está sendo processada" />
			)}
		</Modal>
	)

	return (
		<Component className={styles['document-sign-rt']}>
			{loading && <PreLoader duration={loadingDuration} handleLoadingFinish={() => setLoading(false)} />}
			{(!documentoPasswordValid && !loading) && renderModalPassword()}
			{(documentoPasswordValid && !loading && operationFeedback.showFeedback === true) && renderModalFeedback()}

			{(showDocumentPreview && !loading) &&
				<>
					<Component className={styles['document-sign-preview']}>
						{files.map((file: string) => (
							<>
								<img alt="" src={`data:image/jpeg;base64,${file}`} />
								<br />
							</>
						))}
					</Component>

					<Component className={styles['documento-sign-rt-action']}>

						{signature?.status === 'opened' &&
							<>
								<Button
									title='Assinar'
									success lg
									action={() => {
										changeSignatureStatus('signed');
									}}
								/>
								<Button
									title='Rejeitar'
									lg
									danger
									action={() => {
										changeSignatureStatus('refused');
									}}
								/>
							</>
						}
						{signature?.status !== 'opened' && signature?.status !== 'copy' && (
							<Component className={styles['document-status']}>
								<h3>
									<FontAwesomeIcon icon={['fal', (signature?.status === 'signed' ? 'check-circle' : 'times-circle')]} className={styles['icon-status-sign']} /> Documento {signature?.status === 'signed' ? 'Assinado' : 'Rejeitado'} em <strong>{formatLocalDate(signature?.signatureDate, false, 'YYYY-MM-DDTHH:mm:SS[.sss]','DD/MM/YYYY HH:mm')}</strong>
								</h3>
							</Component>
						)}
						{signature?.status === 'copy' &&
							<Component className={styles['document-status']}>
								<h3>
									Cópia do documento
								</h3>
							</Component>
						}
					</Component>

				</>
			}

		</Component>
	);
}

export default Sign;