
import { library } from '@fortawesome/fontawesome-svg-core'

import { 
    faPlus,
	faCheck,
	faTimes,
	faEdit,
	faPen,
	faTrash,
	faSearch,
	faUsers,
	faFile,
	faMailBulk,
	faReply,
	faCopy,
	faSave
} from '@fortawesome/free-solid-svg-icons'

/*import {
	faCheck as fasCheck, 
} from '@fortawesome/pro-solid-svg-icons';*/

import {
    faCheck as falCheck, 
	faClock as falClock, 
	faBan as falBan, 
	faTimesCircle as falTimesCircle, 
	faEnvelopeOpen as falEnvelopeOpen, 
	faMinusCircle as falMinusCircle,
	faFilePdf as falFilePdf,
	faFileWord as falFileWord,
	faFileImage as falFileImage,
	faKey as falKey,
	faSearch as falSearch,
	faCheckCircle as falCheckCircle,
	faQuestionCircle as falQuestionCircle
} from '@fortawesome/pro-light-svg-icons';

library.add(
    falBan, falCheck, falClock, falEnvelopeOpen, falMinusCircle, falTimesCircle, falFilePdf, falFileWord,
	falKey,falSearch,falFileImage,falCheckCircle,faReply, faSave, faCopy,

	faPlus, faCheck, faTimes, faEdit, faPen, faTrash, faSearch,faUsers,faFile,faMailBulk,falQuestionCircle
);

