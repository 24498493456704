import { Button, Input, Loading, Modal, Panel, Row, Select, Table, TextArea, TextInformation, Upload } from '@optimuminterfaces/revex-react-components/dist/components';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { blobToFile, toObjectUrl } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil'; 
import { validateEmail } from '../../../../utils/ValidationUtil';
import { uploadDocument } from '../../../../services/document.services';

import styles from './New.module.scss';
interface NewDocumentProps {
	Component?: any
}

interface DestinationObject {
	name: string, 
	email: string, 
	type: string, 
	editable: boolean 

}

interface OperationFeedbackModel {
	status: string,
	message: string,
	showFeedback: boolean
}

const New = ({ Component = 'div' }: NewDocumentProps) => {
	const [destinations, setDestinations] = useState([
		{ name: '', email: '', type: 'sign', editable: true }
	]);

	const [validations, setValidations] = useState([
		{ inputname: true, inputemail: true, selecttype: true }
	]);
	const [message, setMessage] = useState<string>('');
	const [file, setFile] = useState();
	const [operationFeedback, setOperationFeedback] = useState<OperationFeedbackModel>({
		'status': '',
		'message': '',
		'showFeedback': false
	});
	let history = useHistory();

	const types: {[key: string]: string} = {
		'sign':'Solicitar Assinatura',
		'copy':'Enviar cópia'
	}
	const typeOptions = [
		{key:"sign", value: "Solicitar Assinatura", selected: false},
		{key:"copy", value: "Enviar cópia", selected: false}
	]

	const setEditableDestination = (index: number) =>{
		const dts = [...destinations];

		dts.forEach(d => {
			d.editable=false;
		});

		dts[index].editable = true;

		setDestinations(dts);
	}

	const setNotEditable = (index?: number) =>{
		const dts = [...destinations];
		console.log('index',index)
		if(index !== undefined){
			
			dts[index].editable=false;
		}else{
			dts.forEach(d => {
				d.editable=false;
			})
		}

		setDestinations(dts);
	}

	const addDestination = () =>{
		const inputValidation = [...validations];
		inputValidation.push({ inputname: true, inputemail: true, selecttype: true });
		setValidations(inputValidation);

		const dts = [...destinations];
		dts.push({ name: '', email: '', type: 'sign', editable: true });
		setDestinations(dts);
	}
	const removeDestination = (index: number) =>{
		const dts = [...destinations];

		dts.splice(index,1);

		setDestinations(dts);

		const inputValidation = [...validations];

		inputValidation.splice(index,1);

		setValidations(inputValidation);
	}

	const handleChangeDestination = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		
		if (e) {
            const target = e.target;
			const value = target.value;
			const name = String(target.name);


			const dts = [...destinations];
			dts[index] = {...dts[index], [name]: value}
			
			setDestinations(dts);

        }

	}

	const saveDestination = (index: number) => {
		const dts = [...destinations];
		let hasErrors = false;
		let inputValidate = [...validations];

		if(!dts[index].name || dts[index].name.trim()===''){
			inputValidate[index] = {...inputValidate[index], 'inputname':false}
			hasErrors = true;
		}

		if(!dts[index].email || dts[index].email.trim()==='' || !validateEmail(dts[index].email)){
			inputValidate[index] = {...inputValidate[index], 'inputemail':false}
			hasErrors = true;
		}

		if(!dts[index].type || dts[index].type.trim()===''){
			inputValidate[index] = {...inputValidate[index],  'selecttype':false}
			hasErrors = true;
		}
		setValidations(inputValidate);
		if(!hasErrors){
			setNotEditable(index);
		}
		
	}

	const renderDestination = (destination: DestinationObject, index: number) => {
		
		if (destination.editable) {
			return (
				<Table.BodyItem key={index}>
					<Table.BodyItem.Item >
						<Input type="text" small 
								name="name"
								inline placeholder="Nome" 
								value={destination.name}
								className={styles['input-rt']} fullWidth
								status={validations[index].inputname?'':'error'}
								handleChange={(e) => handleChangeDestination(e,index)}
								/>
					</Table.BodyItem.Item>
					<Table.BodyItem.Item align='center'>
						<Input type="email" small
								name="email"
								inline placeholder="Email"
								value={destination.email}
								className={styles['input-rt']} fullWidth
								status={validations[index].inputemail?'':'error'}
								handleChange={(e) => handleChangeDestination(e,index)}
								/>
					</Table.BodyItem.Item>
					<Table.BodyItem.Item>
						<Select small value={types[destination.type]}
								name=""
								fullWidth
								options={typeOptions} 
								className={styles['input-rt']}
								status={validations[index].selecttype?'':'error'}
								handleChange={(key) => {
									const dts = [...destinations];
									dts[index] = {...dts[index], 'type': key}
									
									setDestinations(dts);
								}}/>
					</Table.BodyItem.Item>
					<Table.BodyItem.Item align='center'>
						<Component className={styles['actions']}>
							<Button sm success 
								disabled={!!destination.name && !!destination.email ? false : true} 
								icon="save" action={() => saveDestination(index)}/>
							{destinations.length > 1 && <Button sm outlined icon="trash" action={() => removeDestination(index)}/>}
						</Component>
					</Table.BodyItem.Item>
				</Table.BodyItem>
			);
		}else{
			return (
				<Table.BodyItem key={index}>
					<Table.BodyItem.Item>
						{destination.name}
					</Table.BodyItem.Item>
					<Table.BodyItem.Item align='center'>
						{destination.email}
					</Table.BodyItem.Item>
					<Table.BodyItem.Item align='center'>
						{types[destination.type]}
					</Table.BodyItem.Item>
					<Table.BodyItem.Item align='center'>
						<Component className={styles['actions']}>
							<Button sm outlined icon="pen" action={() => setEditableDestination(index)}/>
							<Button sm outlined icon="trash" action={() => removeDestination(index)}/>
						</Component>
					</Table.BodyItem.Item>
				</Table.BodyItem>
			)
		}
	}

	const renderModalFeedback = () => (
		<Modal 
			backdrop sm 
			title='Cadastro de Documento' 
			className={styles['modal-new-feedback']} >
			{operationFeedback.status !== 'PROCESSING' && (
				<>
					<TextInformation
					iconColor={operationFeedback.status ==='SUCCESS'?'#3AA76D':'#D4644D'} 
					icon={operationFeedback.status==='SUCCESS'?'check-circle':'times-circle'} 
					iconType="fal"
					text={operationFeedback.message} />
					<Component className={styles['button-action']}>
						<Button 
							md title="OK"
							danger={operationFeedback.status !=='SUCCESS'}
							primary={operationFeedback.status ==='SUCCESS'}
							action={() => {
								setOperationFeedback({...operationFeedback, 'showFeedback':false});

								if (operationFeedback?.status ==='SUCCESS') {
									history.push('/assinadorMF/documents');
								}
							}}></Button>
					</Component>
				</>
			)}
			{operationFeedback.status === 'PROCESSING' && (
				<Loading text="Sua solicitação está sendo processada" />
			)}
		</Modal>
	);

	const cleanFormData = () =>{
		setDestinations([{ name: '', email: '', type: 'sign', editable: true }]);
		setValidations([{ inputname: true, inputemail: true, selecttype: true }]);
		setFile(undefined);
		setMessage('');
	}
	
	const saveDocument = () => {
		setOperationFeedback({...operationFeedback, status:'PROCESSING',showFeedback:true})

		let dest:DestinationObject[] = [];

		destinations.forEach(destination => {
			if(destination.editable === false){
				dest.push(destination);
			}
		});

		if(!file || !message || dest.length===0){
			setOperationFeedback({...operationFeedback, message:'Todas os campos devem ser preenchidos', status:'ERROR', showFeedback:true});
			return;
			
		} else if (message.length > 1000) {
			setOperationFeedback({
				status: 'ERROR',
				message: 'Limite de 1000 caracteres atingido!',
				showFeedback: true
			});
			return;
		}

		blobToFile(toObjectUrl(file![0]), file![0]['name'], file![0]['type'])
		.then(file => {
			uploadDocument(file, message, dest).then(
				response => {
					if(response.data.status==='SUCCESS'){
						cleanFormData();
						setOperationFeedback({...operationFeedback, message:`Documento enviado com sucesso`, status:response.data.status, showFeedback:true});
						
					}else{
						setOperationFeedback({...operationFeedback, message:response.data.message, status:response.data.status, showFeedback:true});
					}
				},
				error => { 
					Promise.reject(error);
					setOperationFeedback({...operationFeedback, status:'ERROR', message: 'Error, tente novamente',showFeedback:true})
				}
			)
		});

		
		
	}

	return (
		<Component className={styles['document-new-rt']}>
			<Row>
				<Row.Item sm={12} lg={12} mg={12} className=''>
					{(operationFeedback.showFeedback === true) && renderModalFeedback()}
				</Row.Item>
				<Row.Item sm={12} lg={12} mg={12} className=''>
					<Panel title="Novo documento para assinatura" defaul>
						<>
							<h3><FontAwesomeIcon icon='file'/> Documento</h3>
							<Component className={styles['document-new-rt-upload']}>
								<Upload square={true} required={true} onChangeFiles={(files) => setFile(files)} />
							</Component>

							<h3><FontAwesomeIcon icon='mail-bulk'/> Mensagem</h3>
							<TextArea 
								rows={10} 
								placeholder="Mensagem para assinante"
								fullWidth
								handleChange={(e:React.ChangeEvent<HTMLInputElement>) => {
									const target = e.target;
									const value = target.value;
									setMessage(value)
								}}
								value={message!}
							/>

							<h3><FontAwesomeIcon icon='users'/> Destinatários</h3>
							<Component className={styles['document-new-rt-destinations']}>

								<Table primary>
									<Table.Header>
										<Table.HeaderItem>
											<Table.HeaderItem.Item>Nome do destinatário</Table.HeaderItem.Item>
											<Table.HeaderItem.Item>E-mail</Table.HeaderItem.Item>
											<Table.HeaderItem.Item>Tipo</Table.HeaderItem.Item>
											<Table.HeaderItem.Item>Opções</Table.HeaderItem.Item>
										</Table.HeaderItem>
									</Table.Header>
									<Table.Body>
										{
											destinations.map((destination, index) =>renderDestination(destination, index))
										}
										<Table.BodyItem >
											<Table.BodyItem.Item colSpan={4} align='center'>
												<Button icon='plus' title='Destinatário' sm primary action={() => addDestination()}/>
											</Table.BodyItem.Item>
										</Table.BodyItem>
									</Table.Body>
								</Table>
							</Component>
							<Component className={styles['document-new-rt-actions']}>
								<Button title="Enviar Documento" primary action={() => saveDocument()} className={styles['button']}/>
								<Button title="Cancelar" action={() => history.push('/assinadorMF/documents')} className={styles['button']}/>
							</Component>
						</>
					</Panel>
				</Row.Item>
			</Row>
		</Component>
	)
}

export default New;