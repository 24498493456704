import React, { useEffect, useState } from 'react';
import { Button, Loading, Modal, PageTitle, Panel, Row, TextInformation, Timeline } from '@optimuminterfaces/revex-react-components/dist/components';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { useHistory, useParams } from 'react-router';

import { fetchDocumentDetails } from '../../../../services/document.services';
import { resendDocumentEmail } from '../../../../services/signature.services';

import Document from '../../../../models/Document';
import Signature from '../../../../models/Signature';
import Log from '../../../../models/Log';
import StatusInformation from '../../../atoms/StatusInformation/StatusInformation';

import imageUser from '../../../../assets/images/avatar.png';

import styles from './Show.module.scss';
interface ShowDocumentProps {
	Component?: any
}

interface ShowDocumentParams {
	id: string
}

const Show = ({ Component = 'div' }: ShowDocumentProps) => {

	const [params, setParams] = useState<ShowDocumentParams>(useParams());
	const [loading, setLoading] = useState<boolean>(false);
	const [signatures, setSignatures] = useState<Signature[]>();
	const [document, setDocument] = useState<Document>();
	const [files, setFiles] = useState<any[]>([]);
	const [logs, setLogs] = useState<Log[]>();
	const [resendParams, setResendParams] = useState({
		showModal: false,
		status: 'question',
		name: '',
		signatureId: '',
		message: ''
	})

	let history = useHistory();

	const URL_MICRO_FRONT_END = process.env.REACT_APP_URL;

	useEffect(() => {
		const documentDetails = () => {
			setLoading(true);
			fetchDocumentDetails(params.id).then(
				(response: any) => {
					setLoading(false);

					if (response.data.status === 'SUCCESS') {
						setFiles(response.data.data.file);
						setSignatures(response.data.data.signatures);
						setDocument(response.data.data.document);
						setLogs(response.data.data.activities.logs);
					} else {
						history.push('/assinadorMF/documents/new');
					}

				},
				error => {
					Promise.reject(error)
				}
			);

		}
		documentDetails()

	}, [params, history]);

	const resend = () => {
		setResendParams({ ...resendParams, status: 'operation' });
		resendDocumentEmail(resendParams.signatureId).then(
			(response: any) => {
				setResendParams({
					...resendParams,
					status: response.data.status.toLowerCase(),
					message: response.data.message
				});
				if (response.data.status === 'SUCCESS') {
					console.log(response.data);
				} else {

				}

			},
			error => {
				Promise.reject(error)
			}
		)
	}

	const renderModalResend = () => {
		return (
			<Modal md title='Confirmação'>
				{resendParams.status === 'question' &&
					<Component>
						<TextInformation icon='question-circle' iconType='fal' text={`Tem certeza que deseja reenviar o email para ${resendParams.name}`} />
						<Component className={styles['modal-resend-actions']}>
							<>
								<Button md primary title='Sim' action={() => resend()} />
								<Button md title='Não' action={() => { setResendParams({ ...resendParams, showModal: false }) }} />
							</>
						</Component>
					</Component>
				}
				{resendParams.status === 'operation' &&
					<Component>
						<Loading text='Enviando novamente o(s) email(s), aguarde...' />
					</Component>
				}
				{(resendParams.status !== 'question' && resendParams.status !== 'operation') &&
					<Component>
						<TextInformation
							iconType='fal'
							iconColor={resendParams.status === 'success' ? '#3AA76D' : '#D4644D'}
							icon={(resendParams.status === 'success') ? 'check-circle' : 'times-circle'}
							text={resendParams.message} />
						<Component className={styles['modal-resend-actions']}>
							<>
								<Button md primary title='OK' action={() => { setResendParams({ ...resendParams, showModal: false, status: 'question' }) }} />
							</>
						</Component>
					</Component>
				}
			</Modal>
		)
	}

	return (
		<Component className={styles['document-show-rt']}>
			<Row>
				<Row.Item lg={12} md={12} sm={12} className=''>
					<PageTitle.Small title='Detalhes do documento' returnUrl='#/assinadorMF/documents' />
				</Row.Item>
			</Row>
			{loading &&
				<Row>
					<Row.Item sm={12} md={12} lg={12} className=''>
						{(loading) && <Loading text='Buscando informações do documento' />}
					</Row.Item>
				</Row>
			}
			{!loading &&
				<Row>
					<Row.Item lg={3} md={3} sm={12} className=''>
						<Panel title='Detalhes' className={styles['']} noTitleBorder>
							<Component className={styles['document-detail']}>
								<dl>
									<dt>Solicitante</dt>
									<dd>{document?.requesterName}</dd>
									<dt>Solicitante - Email</dt>
									<dd>{document?.requesterEmail}</dd>
									<dt>Data da Solicitação</dt>
									<dd>{formatLocalDate(document?.created!, false, 'YYYY-MM-DDTHH:mm:SS[.sss]', 'DD/MM/YYYY HH:mm')}</dd>
									{!!signatures && signatures.length > 0 && !!signatures[0].deadline &&
										<>
											<dt>Data Limite</dt>
											<dd>{formatLocalDate(signatures[0].deadline, false, 'YYYY-MM-DDTHH:mm:SS[.sss]', 'DD/MM/YYYY HH:mm')}</dd>
										</>
									}
									{!!document && !!document.reasonForCancellation &&
										<>
											<dt>Motivo do cancelamento</dt>
											<dd>{document.reasonForCancellation}</dd>
										</>
									}
								</dl>

							</Component>
						</Panel>
						<Panel title='Destinatário(s)' className={styles['']} noTitleBorder>
							<Component>
								{signatures?.map(signature => (
									<Component key={signature.id} className={styles['signature-item']}>
										<Component>
											{`${signature.subscriberName} - ${signature.subscriberEmail}`}
											{signature.status === 'waiting' &&
												<Component className={styles['signature-actions']}>
													<Button xs title='Reenviar' icon='reply'
														action={() => {
															setResendParams({ ...resendParams, name: signature.subscriberName!, signatureId: signature.id!, showModal: true })
														}}
													/>
												</Component>
											}
										</Component>
										<Component><StatusInformation statusName={signature.status!} lastUpdate={signature.modified!} /></Component>
										{resendParams.showModal && renderModalResend()}
									</Component>
								))}
							</Component>
						</Panel>
						{!!logs && logs.length > 0 &&
							<Panel title='Atividade(s)' className={styles['']} noTitleBorder>
								<Timeline>
									{logs?.map(log => (
										<Timeline.Item
											date={formatLocalDate(log?.created!, false, 'YYYY-MM-DDTHH:mm:SS[.sss]', 'DD/MM/YYYY HH:mm')}
											description={log?.description!}
											userName={log?.createdBy!}
											userPhoto={`${URL_MICRO_FRONT_END}${imageUser}`} />
									))}
								</Timeline>
							</Panel>
						}
					</Row.Item>
					<Row.Item lg={9} md={9} sm={12} className=''>
						<Component className={styles['file-preview']}>
							{files.map((file: string, index: number) => (
								<Component key={index} >
									<img alt='' src={`data:image/jpeg;base64,${file}`} />
									<br />
								</Component>
							))}
						</Component>
					</Row.Item>
				</Row>
			}
		</Component>
	)

}

export default Show;