import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './StatusInformation.module.scss';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

interface StatusInformationProps {
	Component?: any,
	statusName: string,
	lastUpdate: string

}

const StatusInformation = ({ statusName, lastUpdate, Component = 'div' }: StatusInformationProps) => {

	const TRANSLATE_DOCUMENT_STATUS: { [key: string]: string } = {
		'waiting': 'Aguardando Assinatura',
		'opened': 'Arquivo Aberto',
		'signed': 'Documento Assinado',
		'refused': 'Documento Rejeitado',
		'canceled': 'Assinatura Cancelada',
		'copy': 'Destinatário em Cópia'

	}

	const TRANSLATE_DOCUMENT_STATUS_ICON: { [key: string]: any } = {
		'waiting': 'clock',
		'opened': 'envelope-open',
		'signed': 'check',
		'refused': 'minus-circle',
		'canceled': 'times-circle',
		'copy': 'copy'

	}

	return (
		<Component className={`${styles['document-status']} ${styles[statusName]}`}>
			<Component className={styles['document-status-icon']}>
				<FontAwesomeIcon icon={['fal', TRANSLATE_DOCUMENT_STATUS_ICON[statusName]]} />
			</Component>
			<Component className={styles['document-status-description']}>
				<Component className={styles['document-status-name']}>{TRANSLATE_DOCUMENT_STATUS[statusName]}</Component>
				<Component className={styles['document-status-last-update']}>{formatLocalDate(lastUpdate, false, 'YYYY-MM-DDTHH:mm:SS[.sss]', 'DD/MM/YYYY HH:mm')}</Component>
			</Component>

		</Component>
	);
}

export default StatusInformation;