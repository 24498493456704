import React from 'react';
import { Panel, Row } from '@optimuminterfaces/revex-react-components/dist/components/index';

const Dashboard = () => {

	return (
		<Row>
			<Row.Item lg={12} md={12} sm={12} className=''>
				<Panel title='adasdasd' noPadding>
					<>
					</>
				</Panel>
			</Row.Item>
		</Row>
	)
}

export default Dashboard;