import React from 'react';
import ApplicationRouter from '../../../router';

import '../../../configs/awesomeFontLibrary';

import './App.scss';
import styles from './App.module.scss';
export interface AppProps {
    history?: any;
}

function App({history}: AppProps) {
    return (
        <div className={`${styles['app']} birdman-app`}>
            <ApplicationRouter history={history}/>
        </div>
    );
}

export default App;